import React, { useState } from "react";
import { Link } from "gatsby";
import { Row, Col, Button, Container } from "react-bootstrap";
import StarLogo from "../images/icons/star.svg";
import Carousel from "react-multi-carousel";
import { GatsbyImage } from "gatsby-plugin-image";
import "react-multi-carousel/lib/styles.css";

const starCount = 5;

function Review({ review, minHeight, cta }) {
	const shortenText = (text) => {
		if (text.length > 140) {
			const lastSpaceIndex = text.lastIndexOf(" ", 140);
			return `${text.substring(0, lastSpaceIndex)}...`;
		}
		return text;
	};

	return (
		<div
			style={{ minHeight: minHeight }}
			className="google-review-card mx-0 ms-lg-0 me-md-3 me-lg-4 bg-dark-green px-3 px-lg-4 py-4 text-start position-relative d-flex flex-column"
		>
			<div className="d-flex w-100  pb-3 justify-content-start">
				{Array.from(Array(starCount), (_, index) => (
					<img
						key={index}
						src={StarLogo}
						alt="Star"
						className={`d-inline-block ${
							index !== starCount - 1 ? "me-2" : ""
						}`}
						style={{
							maxWidth: "20px",
						}}
					/>
				))}
			</div>
			{review.reviewBody.length > 140 && (
				<p className="text-black pb-2 jost-regular">
					{shortenText(review.reviewBody)}
				</p>
			)}
			{review.reviewBody.length <= 140 && (
				<p className="text-black pb-2 jost-regular">{review.reviewBody}</p>
			)}

			<div className="text-uppercase text-black d-block fs-6 pb-0 ">
				<span className="jost-italic fs-6">From:</span>
				<span className="jost-medium fs-6 ps-1" style={{ fontStyle: "italic" }}>
					{review.author}
				</span>
			</div>

			<Button
				style={{ borderRadius: "0px" }}
				variant="outline"
				as={Link}
				target={cta?.target ?? "_blank"}
				rel="noreferrer"
				href={cta?.url}
				className="p-0 text-accent jost-bold text-decoration-underline mt-auto text-start"
			>
				Read more on Google
			</Button>
		</div>
	);
}

const ReviewsSlider = ({ reviews, heading, cta, reviewTitle, logoImage }) => {
	return (
		<>
			<section className="py-4 py-md-5 py-lg-7 review-section">
				<Container>
					<Row className="g-5 gx-lg-7 align-items-center justify-content-center">
						<Col lg={8}>
							<h2 className="line-heading text-center mb-5 fs-1 merriweather-regular">
								{heading}
							</h2>
						</Col>
					</Row>
					<Row>
						<Col md={4} xl={3} className="pb-4 pb-md-6">
							<div className="google-review-card">
								<div className="d-flex flex-column h-100">
									<div
										style={{
											borderBottom: "1px solid rgba(133, 133, 133, 0.30)",
										}}
										className="h-100 d-flex flex-column py-3 px-6 p-md-3 py-lg-4 px-lg-5 justify-content-center"
									>
										<h2 className="text-light-black jost-regular">
											{reviewTitle}
										</h2>
										<div className="d-flex align-items-center">
											<span className="fs-3 fs-lg-1 jost-medium">5.0</span>
											<div className="d-flex ms-2 ms-lg-3">
												{Array.from(Array(5), (_, index) => (
													<img
														key={index}
														src={StarLogo}
														alt="Star"
														className={`d-inline-block ${
															index !== 5 - 1 ? "me-2" : ""
														}`}
														style={{
															maxWidth: "20px",
														}}
													/>
												))}
											</div>
										</div>
									</div>
									<div className="mt-auto px-4 px-md-0">
										<GatsbyImage
											style={{ objectFit: "contain" }}
											image={logoImage}
											alt={""}
											height={112}
											className="review-img"
										/>
									</div>
								</div>
							</div>
						</Col>
						<Col md={8} xl={9}>
							<div className="d-none d-md-block">
								<Carousel
									swipeable={true}
									draggable={false}
									autoPlay={false}
									autoPlaySpeed={2000}
									showDots={true}
									responsive={{
										desktop: {
											breakpoint: { max: 3000, min: 1024 },
											items: 3,
										},
										tablet: {
											breakpoint: { max: 1024, min: 464 },
											items: 2,
										},
										mobile: {
											breakpoint: { max: 464, min: 320 },
											items: 1,
										},
									}}
									infinite={true}
									arrows={true}
									className="pb-6"
								>
									{reviews.slice(0, 10).map((review, i) => (
										<Review minHeight="350px" review={review} cta={cta} />
									))}
								</Carousel>
							</div>
							<div className="d-block d-md-none">
								<Carousel
									swipeable={true}
									draggable={false}
									autoPlay={true}
									autoPlaySpeed={2000}
									showDots={true}
									responsive={{
										desktop: {
											breakpoint: { max: 3000, min: 1024 },
											items: 3,
										},
										tablet: {
											breakpoint: { max: 1024, min: 464 },
											items: 2,
										},
										mobile: {
											breakpoint: { max: 464, min: 320 },
											items: 1,
										},
									}}
									infinite={true}
									arrows={true}
									className="pb-6"
								>
									{reviews.slice(0, 10).map((review, i) => (
										<Review minHeight="350px" review={review} cta={cta} />
									))}
								</Carousel>
							</div>
						</Col>
					</Row>

					{cta && cta?.url && (
						<Col>
							<div className="text-center mt-6">
								<Button
									style={{ borderRadius: "0px" }}
									variant="accent"
									as={Link}
									target={cta?.target ?? "_blank"}
									rel="noreferrer"
									href={cta?.url}
									className="text-white py-3 px-5 w-100 w-md-auto"
								>
									{cta?.title}
								</Button>
							</div>
						</Col>
					)}
				</Container>
			</section>
		</>
	);
};

export default ReviewsSlider;
